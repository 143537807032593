import { AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useCurrentUserContext } from '@/contexts';
import { useLauncherAction } from '@/hooks/useLauncher';
import { LAUNCH_KEY } from '@/pages/QuickCreate/components/GoogleCalendar/constants';
import { GoogleCalendarButton } from '@/pages/QuickCreate/components/GoogleCalendar/GoogleCalendarButton';
import { GoogleCalendarPicker } from '@/pages/QuickCreate/components/GoogleCalendar/GoogleCalendarPicker';
import {
  useCurrentDestination,
  useLegacyExportMagicEvents,
  useMultiExportStore,
} from '@/pages/QuickCreate/components/GoogleCalendar/hooks';
import { usePickerIntegrations } from '@/pages/QuickCreate/components/GoogleCalendar/hooks/usePickerIntegrations';
import { IntegrationErrors } from '@/pages/QuickCreate/components/GoogleCalendar/MultiExportCard/IntegrationErrors';
import { MultiExportModal } from '@/pages/QuickCreate/components/GoogleCalendar/MultiExportCard/MultiExportModal';
import { SuccessState } from '@/pages/QuickCreate/components/GoogleCalendar/MultiExportCard/SuccessState';
import type { Destination } from '@/pages/QuickCreate/components/GoogleCalendar/types';
import { usePlatformExports } from '@/pages/QuickCreate/hooks/usePlatformExports';
import { hasMultiExported } from '@/pages/QuickCreate/utils/googleCalendar';
import type { DecoratedIntegration } from '@/pages/Schedule/hooks/useIntegrations';
import { type Calendar, PlatformTypes } from '@/types/gql.generated';
import { useToast, Text, Flex, Spinner, Box, MotionFlex } from '@/ui';
import { useQuickCreateContext } from '../../../contexts';

const variants = {
  show: {
    opacity: 1,
    transition: { duration: 0.1 },
  },
  hide: {
    opacity: 0,
    transition: { duration: 0.1 },
  },
};

export const MultiExportCard = () => {
  const { job } = useQuickCreateContext();
  const { currentUser } = useCurrentUserContext();
  const { integrations, isLoading: isLoadingIntegrations } =
    usePickerIntegrations();
  const { destination, setDestination } = useCurrentDestination();
  const toast = useToast();
  const [modalOpen, setModalOpen] = useState(false);

  const { legacyExportMagicEvents, isPending: isExporting } =
    useLegacyExportMagicEvents();
  const {
    isOpen: isControlOpen,
    open: openControl,
    close: closeControl,
    toggle: toggleControl,
  } = useMultiExportStore();

  const { platformExports } = usePlatformExports();
  const [overrideSuccessState, setOverrideSuccessState] = useState(false);

  const entries = job?.events ?? [];
  const platformExport = platformExports.find(
    ({ platform }) => platform === destination?.platform
  );
  const hasExportedDestination = Boolean(
    destination && hasMultiExported(destination, platformExports, entries)
  );
  const hasAddedAll = Boolean(hasExportedDestination && platformExport);
  const showSuccessState =
    hasExportedDestination && platformExport && !overrideSuccessState;

  useEffect(() => {
    if (hasExportedDestination) {
      openControl();
    }
  }, [hasExportedDestination, openControl]);

  useEffect(() => {
    if (!currentUser) {
      closeControl();
    }
  }, [currentUser, closeControl]);

  useLauncherAction(LAUNCH_KEY, () => {
    setModalOpen(true);
    openControl();
  });

  const addToGoogleButtonText =
    entries.length > 1
      ? `Add all ${entries.length} to Google`
      : 'Add to Google';

  const handleAdd = (destination: Destination) => {
    legacyExportMagicEvents(destination, entries, {
      onSuccess: () => setOverrideSuccessState(false),
      onError: () => {
        toast.error(
          'Whoops! We had some trouble sharing to your calendar. Please try again.'
        );
      },
    });
  };

  const handleReset = () => {
    setOverrideSuccessState(true);
  };

  const handleModalSelect = (
    integration: DecoratedIntegration,
    calendar: Calendar
  ) => {
    const destination: Destination = {
      calendar,
      integration,
      platform: PlatformTypes.GoogleIntegration,
    };
    setDestination(destination);
    handleAdd(destination);
    setModalOpen(false);
  };

  return (
    <Box>
      <MultiExportModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onSelect={handleModalSelect}
      />

      <AnimatePresence mode="popLayout">
        {!isControlOpen && (
          <MotionFlex
            align="center"
            animate="show"
            exit="hide"
            initial="show"
            justify="flex-end"
            key="closed"
            variants={variants}
          >
            {integrations.length > 0 ? (
              <GoogleCalendarButton colorScheme="brand" onClick={toggleControl}>
                {addToGoogleButtonText}
              </GoogleCalendarButton>
            ) : (
              <GoogleCalendarButton
                colorScheme="brand"
                isAuth
                isLoading={isLoadingIntegrations}
                job={job}
                launch={LAUNCH_KEY}
              >
                {addToGoogleButtonText}
              </GoogleCalendarButton>
            )}
          </MotionFlex>
        )}

        {isControlOpen && (
          <MotionFlex
            animate="show"
            exit="hide"
            flex="1"
            initial="hide"
            key="opened"
            variants={variants}
            w="100%"
          >
            {!isLoadingIntegrations && (
              <IntegrationErrors onReportClick={toggleControl} />
            )}

            {isLoadingIntegrations ? (
              <Flex align="center" flex="1" gap="2" w="100%">
                <Spinner size="sm" />
                Loading calendars
              </Flex>
            ) : showSuccessState ? (
              <SuccessState
                backgroundColor="var(--secondary-color)"
                platformExport={platformExport}
                w="100%"
                onResetClick={handleReset}
              />
            ) : integrations.length > 0 ? (
              <Flex align="center" flex="1" gap="2">
                <Flex direction="column" flex="1" gap="2">
                  <Text fontWeight="bold">Select a calendar</Text>
                  <GoogleCalendarPicker
                    value={destination}
                    onChange={setDestination}
                  />
                </Flex>
                <GoogleCalendarButton
                  alignSelf="flex-end"
                  colorScheme="brand"
                  isDisabled={!destination || hasAddedAll}
                  isLoading={isExporting}
                  onClick={() => destination && handleAdd(destination)}
                >
                  {hasAddedAll ? 'Added! 🎉' : 'Add'}
                </GoogleCalendarButton>
              </Flex>
            ) : null}
          </MotionFlex>
        )}
      </AnimatePresence>
    </Box>
  );
};
