import { Flex, type FlexProps } from '@/ui';
import { usePageExperienceContext } from '../contexts/PageExperienceContext';

export const Layout = (props: FlexProps) => {
  const { config } = usePageExperienceContext();

  return (
    <Flex
      direction="column"
      flex="1"
      p={{ base: 3, md: 8 }}
      sx={{
        ...config.theme,
        bgColor: 'var(--background-color)',
        color: 'var(--text-color)',
        'a, .tiptap a': {
          color: 'var(--link-color)',
        },
      }}
      {...props}
    />
  );
};
