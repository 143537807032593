import { PlatformIcon } from '@/components/PlatformIcon';
import { PlatformTypes } from '@/types/gql.generated';
import {
  Menu,
  MenuButton,
  Button,
  Icon,
  MenuList,
  MenuItem,
  Link,
  type LinkProps,
  type MenuItemProps,
} from '@/ui';
import { usePageExperienceContext } from '../../contexts/PageExperienceContext';
import Calendar from './calendar.svg?react';

const LinkMenuItem = (props: MenuItemProps & LinkProps) => (
  <MenuItem
    _hover={{ textDecor: 'none' }}
    as={Link}
    color="dark.500 !important"
    target="_blank"
    textDecor="none"
    {...props}
  />
);

export const SubscribeMenu = () => {
  const { config } = usePageExperienceContext();

  return (
    <Menu matchWidth>
      <MenuButton
        alignSelf="flex-start"
        as={Button}
        colorScheme="dark"
        fontSize={config.addToCalendar === 'none' ? 'md' : 'sm'}
        leftIcon={<Icon as={Calendar} boxSize="18px" />}
        textDecor="underline"
        variant="link"
      >
        Subscribe to all events
      </MenuButton>
      <MenuList>
        <LinkMenuItem
          href={config.subscribe?.google}
          icon={<PlatformIcon platform={PlatformTypes.GoogleLink} />}
        >
          Google
        </LinkMenuItem>
        <LinkMenuItem
          href={config.subscribe?.outlook}
          icon={<PlatformIcon platform={PlatformTypes.OutlookLink} />}
        >
          Outlook
        </LinkMenuItem>
        <LinkMenuItem
          href={config.subscribe?.apple}
          icon={<PlatformIcon platform={PlatformTypes.AppleIcs} />}
        >
          Apple (Mobile)
        </LinkMenuItem>
        <LinkMenuItem
          href={config.subscribe?.other}
          icon={<PlatformIcon platform={PlatformTypes.OtherIcs} />}
        >
          Other
        </LinkMenuItem>
      </MenuList>
    </Menu>
  );
};
