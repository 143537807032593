import { AppLoader } from '@/components/AppLoader';
import { QuickCreateErrorMessage } from '@/components/QuickCreateErrorMessage';
import { usePageTitle } from '@/hooks/usePageTitle';
import { Flex } from '@/ui';
import { isGQLErrorOfType, type QueryError } from '@/utils/errors';
import { NotFound } from '../../components/NotFound';
import { useQuickCreateContext } from '../../contexts/QuickCreateContext';
import { Details } from './components/Details';
import { Layout } from './components/Layout';

type Props = {
  isLoading: boolean;
  error: QueryError | null;
};

export default function PageExperiencePoC({ isLoading, error }: Props) {
  const { page, job } = useQuickCreateContext();
  usePageTitle(page?.title);

  return (
    <Layout>
      <Flex
        direction="column"
        flex="1"
        maxW={{ md: '900px' }}
        minW={{ md: '900px' }}
        mx="auto"
      >
        {isLoading ? (
          <AppLoader />
        ) : isGQLErrorOfType(error, 'QuickEntriesPageNotFound') ? (
          <NotFound />
        ) : error ? (
          <QuickCreateErrorMessage error={error} />
        ) : job ? (
          <Details />
        ) : null}
      </Flex>
    </Layout>
  );
}
