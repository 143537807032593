import { EmailIcon } from '@chakra-ui/icons';
import {
  type ChangeEventHandler,
  type FormEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLastSendToEmail } from '@/pages/QuickCreate/hooks/useLastSendToEmail';
import { useSendQuickEntry } from '@/pages/QuickCreate/hooks/useSendQuickEntry';
import type { DecoratedMagicEvent } from '@/pages/QuickCreate/types';
import {
  useToast,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
} from '@/ui';
import { shakeElement } from '@/utils/shakeElement';
import { isValidEmail } from '@/utils/validation';

type Props = {
  entry: DecoratedMagicEvent | undefined;
  onSuccess: () => void;
  onCancel: () => void;
};

export const EmailForm = ({ entry, onSuccess, onCancel }: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { sendQuickEntry, reset, isPending, isSuccess } = useSendQuickEntry();
  const { lastEmail, setLastEmail } = useLastSendToEmail();
  const toast = useToast();
  const [email, setEmail] = useState(lastEmail);

  useEffect(() => {
    if (isSuccess) {
      setEmail(lastEmail);
    }
  }, [isSuccess, lastEmail]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    if (isValidEmail(email)) {
      setLastEmail(email);

      if (entry) {
        sendQuickEntry(entry.id, email, {
          onSuccess,
          onError: () => {
            toast.error('Error sending event, please try again');
          },
        });
      }
    } else {
      shakeElement(containerRef);
    }
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const email = event.currentTarget.value;
    setEmail(email);

    if (email.length > 0 && isSuccess) {
      reset();
    }
  };

  return (
    <Flex
      align="flex-end"
      as="form"
      direction="column"
      gap="2"
      minW={{ md: 300 }}
      w="100%"
      // @ts-expect-error - onSubmit is a valid prop with `as`
      onSubmit={handleSubmit}
    >
      <InputGroup ref={containerRef}>
        <InputLeftElement pointerEvents="none">
          <EmailIcon color="gray.300" />
        </InputLeftElement>
        <Input
          _hover={{ borderColor: 'gray.500' }}
          _placeholder={{ fontSize: 'lg', fontWeight: 'medium' }}
          bg="white"
          placeholder="Your email"
          type="email"
          value={email}
          onChange={handleChange}
        />
      </InputGroup>

      <Flex gap="4">
        <Button variant="link" onClick={onCancel}>
          Cancel
        </Button>
        <Button isLoading={isPending} size="sm" type="submit">
          Send invite
        </Button>
      </Flex>
    </Flex>
  );
};
