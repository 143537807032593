import { useIsMobile, useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import type { DecoratedMagicEvent } from '@/pages/QuickCreate/types';
import { PlatformTypes } from '@/types/gql.generated';
import { Button, Menu, MenuButton, type MenuButtonProps, MenuList } from '@/ui';
import { getPlatformAddUrl } from '@/utils/platformLib';
import { ProviderItem } from './ProviderItem';

type Props = MenuButtonProps & {
  entry: DecoratedMagicEvent;
};

export const AddToCalendarMenu = ({ entry, ...props }: Props) => {
  const { isMobile } = useIsMobile();
  const isMobileBreakpoint = useIsMobileBreakpoint();

  const handleClick = (platform: PlatformTypes) => () => {
    const url = getPlatformAddUrl(entry.id, platform, isMobile);

    if (url) {
      window.open(url, '_blank');
    }
  };

  return (
    <Menu isLazy matchWidth={isMobileBreakpoint} placement="bottom-end">
      <MenuButton as={Button} {...props}>
        Add to calendar
      </MenuButton>

      <MenuList shadow="md" zIndex="3">
        <ProviderItem
          provider={PlatformTypes.GoogleLink}
          onClick={handleClick(PlatformTypes.GoogleLink)}
        >
          Google Calendar
        </ProviderItem>
        <ProviderItem
          provider={PlatformTypes.AppleIcs}
          onClick={handleClick(PlatformTypes.AppleIcs)}
        >
          Apple
        </ProviderItem>
        <ProviderItem
          provider={PlatformTypes.OutlookLink}
          onClick={handleClick(PlatformTypes.OutlookLink)}
        >
          Outlook
        </ProviderItem>
        <ProviderItem
          provider={PlatformTypes.OfficeLink}
          onClick={handleClick(PlatformTypes.OfficeLink)}
        >
          Office 365
        </ProviderItem>
        <ProviderItem
          provider={PlatformTypes.OtherIcs}
          onClick={handleClick(PlatformTypes.OtherIcs)}
        >
          Other
        </ProviderItem>
      </MenuList>
    </Menu>
  );
};
