import { ChakraProvider } from '@/ui';
import type { QueryError } from '@/utils/errors';
import { QuickCreateContextProvider } from '../../contexts/QuickCreateContext';
import { useGetQuickEntriesPage } from '../../hooks/useGetQuickEntriesPage';
import { PageExperienceProvider } from './contexts/PageExperienceContext';
import { useTheme } from './hooks/useTheme';
import PageExperiencePoC from './PageExperiencePoC';

type Props = {
  slug: string;
};

type ThemeProviderProps = {
  isLoading: boolean;
  error: QueryError | null;
};

const ThemeProvider = (props: ThemeProviderProps) => {
  const theme = useTheme();

  return (
    <ChakraProvider theme={theme}>
      <PageExperiencePoC {...props} />
    </ChakraProvider>
  );
};

export const PageExperiencePoCLoader = ({ slug }: Props) => {
  const { job, page, reset, isLoading, error } = useGetQuickEntriesPage(
    'platform',
    slug
  );

  return (
    <QuickCreateContextProvider job={job} page={page} onReset={reset}>
      <PageExperienceProvider slug={slug}>
        <ThemeProvider error={error} isLoading={isLoading} />
      </PageExperienceProvider>
    </QuickCreateContextProvider>
  );
};
