import { generatePalette } from 'palette-by-numbers';
import { useMemo } from 'react';
import { theme as baseTheme } from '@/theme';
import { extendTheme, withDefaultColorScheme } from '@/ui';
import { usePageExperienceContext } from '../contexts/PageExperienceContext';

export const useTheme = () => {
  const { config } = usePageExperienceContext();

  return useMemo(() => {
    return extendTheme(
      baseTheme,
      {
        colors: {
          brand: generatePalette(config.theme['--primary-color']),
        },
      },
      withDefaultColorScheme({ colorScheme: 'brand' })
    );
  }, [config]);
};
