import { useIsBreakpoint } from '@/hooks/useIsBreakpoint';
import { DateTime } from '@/pages/QuickCreate/components/EntryList/components/EntryCard/components/EntryForm/components/DateTime';
import { Location } from '@/pages/QuickCreate/components/EntryList/components/EntryCard/components/EntryForm/components/Location';
import { Title } from '@/pages/QuickCreate/components/EntryList/components/EntryCard/components/EntryForm/components/Title';
import type { DecoratedMagicEvent } from '@/pages/QuickCreate/types';
import { RichTextEditor } from '@/pages/Schedule/components';
import { Flex, type FlexProps } from '@/ui';
import { usePageExperienceContext } from '../contexts/PageExperienceContext';
import { AddToCalendarEmail } from './AddToCalendarEmail';
import { AddToCalendarMenu } from './AddToCalendarMenu';

type Props = FlexProps & {
  entry: DecoratedMagicEvent;
};

const noop = () => null;

export const EntryForm = ({ entry, ...props }: Props) => {
  const { config } = usePageExperienceContext();
  const isSmBreakpoint = useIsBreakpoint('sm');

  if (!entry) {
    return;
  }

  const showLocationField = !!entry.locationWithPlace?.name;

  const cta =
    config.addToCalendar === 'web-links' ? (
      <AddToCalendarMenu entry={entry} />
    ) : config.addToCalendar === 'email' ? (
      <AddToCalendarEmail entry={entry} />
    ) : null;

  return (
    <Flex direction="column" gap="3" w="100%">
      <Flex {...props} direction={{ base: 'column', sm: 'row' }} gap="3">
        <Flex direction="column" flex="1" gap="2">
          <Title entry={entry} mb="2" readOnly onChange={noop} />
          <DateTime entry={entry} readOnly onChange={noop} />
          {showLocationField && (
            <Location entry={entry} readOnly onChange={noop} />
          )}
        </Flex>

        {!isSmBreakpoint && cta}
      </Flex>

      {entry.description && (
        <RichTextEditor
          placeholder="Description"
          readOnly
          value={entry.description ?? ''}
        />
      )}

      {isSmBreakpoint && cta}
    </Flex>
  );
};
