import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { AnimateChangeInHeight } from '@/components/AnimateChangeInHeight';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import type { DecoratedMagicEvent } from '@/pages/QuickCreate/types';
import { Button, MotionFlex, type MotionFlexProps, Text } from '@/ui';
import { EmailForm } from './EmailForm';

type Props = MotionFlexProps & {
  entry: DecoratedMagicEvent;
};

export const AddToCalendarEmail = ({ entry, ...props }: Props) => {
  const [showInput, setShowInput] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const key = showInput ? 'form' : 'default';

  return (
    <AnimateChangeInHeight>
      <AnimatePresence initial={false} mode="popLayout">
        <MotionFlex
          align="center"
          animate="enter"
          custom={{ key, isMobileBreakpoint }}
          exit="exit"
          h="100%"
          initial="initial"
          justify={{ base: 'center', sm: 'flex-end' }}
          key={key}
          transition={{ duration: 0.1 }}
          variants={{
            initial: () => ({
              opacity: 0,
            }),
            enter: () => ({
              opacity: 1,
            }),
            exit: () => ({
              opacity: 0,
            }),
          }}
          {...props}
        >
          {isSuccess ? (
            <Text color="brand.500" fontSize="lg" fontWeight="bold">
              Invite sent! 🎉
            </Text>
          ) : showInput ? (
            <EmailForm
              entry={entry}
              onCancel={() => setShowInput(false)}
              onSuccess={() => setSuccess(true)}
            />
          ) : (
            <Button
              flex={{ base: 1, sm: 'none' }}
              onClick={() => setShowInput(true)}
            >
              Add to calendar
            </Button>
          )}
        </MotionFlex>
      </AnimatePresence>
    </AnimateChangeInHeight>
  );
};
